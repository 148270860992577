import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {config} from '../../../../shared/services/config'

@inject(DialogController)
export class Viewletter {
  URL= config.img_url;
    
  constructor(DialogController) {
    this.controller = DialogController;
  }

  activate(viewLetterData) {
    this.editorContent = viewLetterData.Content;
    this.File = viewLetterData.Path;
    this.DisplayFileName = viewLetterData.DisplayFileName;
  }

  downloadFile() {
    const downloadUrl = this.URL+ this.File;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = downloadUrl;
    link.click();
  }

  cancel() {
    this.controller.cancel();
  }
    
}

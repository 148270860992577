import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { Previewedit } from '../PreviewEdit/previewedit';

@inject(ProfileService,JwtService,DialogController,DialogService)
export class Previewletter {
   
  constructor(ProfileService,JwtService,DialogController,DialogService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dialogService = DialogService;
  }

  activate(previewData){
    this.content = previewData.Content;
  }

  previewEdit(){
    this.dialogService.open({viewModel:Previewedit,model: this.content});
  }


  cancel() {
    this.controller.cancel();
 }
   
}

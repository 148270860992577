import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import * as tinymce from 'tinymce';
import { Previewletter } from '../PreviewLetter/previewletter';
import Scrollbar from 'smooth-scrollbar';

@inject(ProfileService,JwtService,DialogController,DialogService)
export class Editletter {

  previewContentData = '';
    
  constructor(ProfileService,JwtService,DialogController,DialogService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dialogService = DialogService;

    this.Employeeid = this.jwtService.getEmployeeId();
  }

  activate(Data) {
    this.EditData = Data;
    this.lettername = this.EditData.TemplateName;
    this.letterSubject = this.EditData.Subject;
    this.editorContent = this.EditData.Content;
    this.letterId = this.EditData.EmployeeLetterID;
  }

  attached() {
  tinymce.init({
    selector: '#content',
    toolbar: 'undo redo | code | image imagetools media filemanager | blocks fontfamily fontsize | bold italic underline strikethrough | preview | table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | codesample',
    plugins: 'code image imagetools media filemanager preview fullscreen codesample',

    setup: editor => {
      editor.on('init', () => {
          this.editor = editor;
          this.editorContent = '';
      });
      editor.on('change', () => {
          this.editorContent = editor.getContent();
      });
      editor.ui.registry.addButton('preview', {
        text: 'Preview',
        onAction: () => {
          editor.execCommand('mcePreview');
        }
      });
    },
    /* enable title field in the Image dialog*/
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    file_picker_types: 'image',
    /* and here's our custom image picker*/
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
  
      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
  
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const id = 'blobid' + (new Date()).getTime();
          const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);
          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });
  
      input.click();
    },
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
  });
  this.previewContentData = this.editorContent;

  // setTimeout(()=>{       
  //   Scrollbar.init(document.querySelector('.editletter-scrollbar'));
  // },1000)
}

previewLetter(){
  const ToPreviewCurrentContent = this.previewContentData ? this.previewContentData : (this.editorContent ? this.editorContent + this.previewContentData : null);
  const previewData = {
    Content : ToPreviewCurrentContent,
  };
  tinymce.activeEditor.execCommand('mcePreview');
  //this.dialogService.open({viewModel:Previewletter,model:previewData})
}

GenerateLetter(){
  const NewLetterData = {
    TemplateName : this.lettername,
    Subject : this.letterSubject,
    Content : this.editorContent,
    SendAcknowledgementEmpIND : false,
    DisciplinaryActionID : null,
    EmployeeLetterID : this.letterId,
    EmployeeID :this.Employeeid
  };
  this.profileService.SaveEmployeeLetter(NewLetterData)
  .then(response => {
    this.controller.ok(true);
  })
}

cancel() {
  this.controller.cancel();
}

}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import * as tinymce from 'tinymce';

@inject(ProfileService,JwtService,DialogController,DialogService)
export class Previewedit {
   
  constructor(ProfileService,JwtService,DialogController,DialogService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dialogService = DialogService;
  }

  activate(editLetterData){
    this.editorContent = editLetterData;
  }

  attached() {
    const textarea = document.getElementById('content');
    tinymce.init({
      selector: `#${textarea.id}`,
      //toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
      setup: editor => {
          editor.on('init', () => {
              this.editor = editor;
              this.editorContent = '';
          });
          editor.on('change', () => {
              this.editorContent = editor.getContent();
              if (typeof this.editorContent === 'string') {
                const tempElement = document.createElement('div');
                tempElement.innerHTML = this.editorContent;
                this.EditorData = tempElement.textContent || tempElement.innerText;
            } else {
                this.EditorData = this.editorContent;
            }
          });
      },
  });
}

detached() {
  tinymce.remove(this.editor);
}

  cancel() {
    this.controller.cancel();
 }
    
}

import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService, DialogController} from 'aurelia-dialog';
import { Addletter } from "./AddLetter/addletter";
import { Editletter } from './EditLetter/editletter';
import { Viewletter } from './ViewLetter/viewletter';
import { Sendtoemployee } from './SendToEmployee/sendtoemployee';
import { Generateletter } from './GenerateLetter/generateletter';
import { Uploadacknowledgement } from './UploadAcknowledgement/uploadacknowledgement';
import { Editacknowledgement } from './EditAcknowledgement/editacknowledgement';
import { Viewacknowledgement } from './ViewAcknowledgement/viewacknowledgement';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import Scrollbar from 'smooth-scrollbar';
import {config} from '../../../shared/services/config';

@inject(ProfileService,JwtService,DialogService,DialogController)
export class Employeeletters {
  pageSize = config.grid_PazeSize;
  topicsPageSize=10;
  showSendToEmployee = false;
  letterMessages = false;
  
  constructor(ProfileService,JwtService,DialogService,DialogController) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.dialogService = DialogService;
    this.dialogController = DialogController;

    this.EmployeeId = this.jwtService.getEmployeeId();
  }

  async attached() {
    this.refreshGrids();
    const sendModesDefaultdata = await this.profileService.GetDefaultDataForSendModes(this.EmployeeId);
    this.DefaultData = await sendModesDefaultdata;

    setTimeout(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.employee-letter-scrollbar'));
            Scrollbar.init(document.querySelector('.employee-letter-history'));
        }
    }, 100);
  }

  async refreshGrids(){
    const EmployeeLettersData = await this.profileService.GetEmployeeLetters(this.EmployeeId);
    this.EmployeeLetters = await EmployeeLettersData;
    const EmployeeLetterCommunicationHistory = await this.profileService.GetEmployeeLetterCommunicationHistory(this.EmployeeId);
    this.CommunicationHistoryData = await EmployeeLetterCommunicationHistory;
  }

  addLetter(){
    this.dialogService.open({viewModel:Addletter})
    .whenClosed((feedback) => {
      if(!feedback.wasCancelled){
      this.refreshGrids();
      this.LetterMessage = 'Employee letter generated successfully.';
      this.letterMessages = true;
         setTimeout(() => {this.LetterMessage = null}, 3000);
      }
    });
  }
  editLetter(editdata){
    this.dialogService.open({viewModel:Editletter,model: editdata})
    .whenClosed((feedback) => {
      if(!feedback.wasCancelled){
      this.refreshGrids();
      this.LetterMessage = 'Employee letter updated successfully.';
      this.letterMessages = true;
         setTimeout(() => {this.LetterMessage = null}, 3000);
      }
    });
  }
  viewLetter(viewdata){
    this.dialogService.open({viewModel:Viewletter,model: viewdata})
  }

  async deleteLetter(data) {
    const LetterID = data.EmployeeLetterID;
    const result = await this.dialogService.open({
       viewModel: AlertpopupComponent,
       model: "",
    }).whenClosed(response => response);
    if (result.wasCancelled) {
       return;
    }
    await this.profileService.DeleteEmployeeLetter(LetterID, this.EmployeeId);
    this.refreshGrids();
    this.LetterMessage = 'Employee letter deleted successfully';
    this.letterMessages = true;
       setTimeout(() => {this.LetterMessage = null}, 3000);
 }

 sendtoEmployee(data){
  this.dialogService.open({viewModel:Sendtoemployee,model:data})
  .whenClosed((feedback) => {
    if(!feedback.wasCancelled){
    this.refreshGrids();
    this.LetterMessage = 'Letter sent successfully.';
    this.letterMessages = true;
       setTimeout(() => {this.LetterMessage = null}, 3000);
    }
  });
 }

 generateLetter(data){
  this.dialogService.open({viewModel:Generateletter,model:data})
  .whenClosed((feedback) => {
    if (!feedback.wasCancelled) {
      const feedbackData = feedback.output;
    } else {
    }
  });
 }
 uploadAck(data){
    this.dialogService.open({viewModel:Uploadacknowledgement,model:data})
  .whenClosed((feedback) => {
    if(!feedback.wasCancelled){
    this.refreshGrids();
    this.LetterMessage = 'Acknowledgement uploaded successfully.';
    this.letterMessages = true;
       setTimeout(() => {this.LetterMessage = null}, 3000);
    }
  });

 }
 editAck(data){
  this.dialogService.open({viewModel:Editacknowledgement,model:data})
  .whenClosed((feedback) => {
    if(!feedback.wasCancelled){
    this.refreshGrids();
    this.LetterMessage = 'Acknowledgement uploaded successfully.';
    this.letterMessages = true;
       setTimeout(() => {this.LetterMessage = null}, 3000);
    }
  });
 }
 viewAck(data){
  this.dialogService.open({viewModel:Viewacknowledgement,model:data})
 }

toggleDropdown($event, letter) {
  const isDropdownOpen = letter.showDropdown;
  this.$displayData.forEach((item) => {
    item.showDropdown = false;
  });
  if (!isDropdownOpen) {
    letter.showDropdown = true;
    document.addEventListener('click', this.closeDropdown);
  }
  $event.stopPropagation();

  // setTimeout(()=>{
  //   var dropdowns = document.getElementsByClassName("dropdown");
  //   for (var i = 0; i < dropdowns.length; i++) {
  //     var dropdown = dropdowns[i];
  //     var rect = dropdown.getBoundingClientRect();
  //     var windowHeight = window.innerHeight || document.documentElement.clientHeight;
  //     if (rect.bottom > windowHeight) {
  //       dropdown.classList.add("upwards");
  //     } else{
  //         dropdown.classList.remove("upwards");
  //     }
  //   }
  // },50)
}

closeDropdown = () => {
  this.$displayData.forEach((item) => {
    item.showDropdown = false;
  });
  document.removeEventListener('click', this.closeDropdown);
}

toggleHistoryDropdown($event, history) {
  const isDropdownOpen = history.showHistoryDropdown;
  this.$topicData.forEach((item) => {
    item.showHistoryDropdown = false;
  });
  if (!isDropdownOpen) {
    history.showHistoryDropdown = true;
    document.addEventListener('click', this.closeHistoryDropdown);
  }
  $event.stopPropagation();

  // setTimeout(()=>{
  //   var dropdowns = document.getElementsByClassName("historydropdown");
  //   for (var i = 0; i < dropdowns.length; i++) {
  //     var dropdown = dropdowns[i];
  //     var rect = dropdown.getBoundingClientRect();
  //     var windowHeight = window.innerHeight || document.documentElement.clientHeight;
  //     if (rect.bottom > windowHeight) {
  //       dropdown.classList.add("upwards");
  //     } else{
  //         dropdown.classList.remove("upwards");
  //     }
  //   }
  // },50)
}

closeHistoryDropdown = () => {
  this.$topicData.forEach((item) => {
    item.showHistoryDropdown = false;
  });
  document.removeEventListener('click', this.closeHistoryDropdown);
}


//use these below functions when need to add separate view dropdown 
viewtoggleDropdown($event, letter) {
    const isDropdownOpen = letter.ViewshowDropdown;
    this.$displayData.forEach((item) => {
        item.ViewshowDropdown = false;
    });
    if (!isDropdownOpen) {
      letter.ViewshowDropdown = true;
        document.addEventListener('click', this.viewCloseDropdown);
    }
    $event.stopPropagation();
}
viewCloseDropdown = () => {
    this.$displayData.forEach((item) => {
        item.ViewshowDropdown = false;
    });
    document.removeEventListener('click', this.viewCloseDropdown);
};

viewHistorytoggleDropdown($event, history) {
  const isDropdownOpen = history.ViewHistoryshowDropdown;
  this.$topicData.forEach((item) => {
      item.ViewHistoryshowDropdown = false;
  });
  if (!isDropdownOpen) {
    history.ViewHistoryshowDropdown = true;
      document.addEventListener('click', this.viewHistoryCloseDropdown);
  }
  $event.stopPropagation();
}
viewHistoryCloseDropdown = () => {
  this.$topicData.forEach((item) => {
      item.ViewHistoryshowDropdown = false;
  });
  document.removeEventListener('click', this.viewHistoryCloseDropdown);
};
  
}

import { inject } from 'aurelia-framework';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import { config } from '../../../../shared/services/config';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

@inject(ProfileService,JwtService,DialogController,ValidationControllerFactory)
export class Sendtoemployee {
   
  options = [];
  SelectedMode = '';
  ChangeMode = '';
  mailBlocks = false;
  EmailBlocks = false;
  SentDateBlock = true;
  DefaultData = [];

  constructor(ProfileService,JwtService,DialogController,controllerFactory) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.controllerFactory = controllerFactory.createForCurrentScope();

    this.EmployeeId = this.jwtService.getEmployeeId();

    ValidationRules.customRule(
      'validEmails',
      (value, obj) => {
         if (typeof value !== 'string') {
            return false;
         }
         const emails = value.split(',');

         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         const invalidEmails = [];
         for (const email of emails) {
            const trimmedEmail = email.trim();
            if (!emailRegex.test(trimmedEmail)) {
               invalidEmails.push(trimmedEmail);
            }
         }
         // Check if any emails are invalid
         if (invalidEmails.length > 0) {
            obj.emailsErrors = ['Invalid email address(es)'];
            return false;
         }
         // Check if emails are separated by commas
         if (emails.length > 1 && value.indexOf(',') === -1) {
            obj.emailsErrors = ['Email(s) should be separated by commas'];
            return false;
         }
         obj.emailsErrors = [];
         return true;
      },
      '${$displayName} is invalid.',
      (obj) => ({
         obj
      })
   );

    ValidationRules
      .ensure('SelectedMode').required().withMessage('Send mode is required')
      .ensure('SentDate').required().withMessage('Sent date is required')
      .ensure('address').required().withMessage('Address is required')
      .ensure('Comments').required().withMessage('Comment is required')
      .ensure('emails').required().withMessage('To email id(s) field is required').then()
      .satisfiesRule('validEmails')
      .ensure('subject').required().withMessage('Subject is required')
      .ensure('body').required().withMessage('Body is required')
      .on(this);
  }

  activate(LetterData){
    this.letterId = LetterData.EmployeeLetterID;
    this.disciplinaryId = LetterData.DisciplinaryActionID;
    this.generatedDate = LetterData.GeneratedDT;
    this.letterPath = LetterData.Path;
    this.letterName = LetterData.DisplayFileName;
  }

  async attached(){
    flatpickr(this.SentDatePicker, {
      closeOnSelect: true,//new Date().toISOString().split("T")[0]
      dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
      minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
      maxDate: 'today', // the maximum selectable date in the calendar (default is null, which means no limit)
      defaultDate: null, // the initial date that should be shown in the calendar (default is null, which means today's date)
      enableTime: false, // whether to allow selecting time in addition to date
      enableSeconds: false, // whether to allow selecting seconds in addition to time
      time_24hr: false, // whether to use a 24-hour clock for time input
      minuteIncrement: 1, // the increment for minutes in the time input
      hourIncrement: 1, // the increment for hours in the time input
      timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
      clickOpens:false,
      onClose: function (selectedDates, dateStr, instance) {
         this.SentDate = dateStr;
      }
   });
   this.SentDatePickerIcon.addEventListener('click', () => {
      this.SentDatePicker._flatpickr.open();
   });

    const LetterSendModesData = await this.profileService.GetLetterSendModes();
    this.LetterSendModes = await LetterSendModesData;
    this.options = Object.entries(this.LetterSendModes).map(([key, value]) => ({ value, key }));

  }

  async SetEmployeeMode(mode){
    this.sentMode = parseInt(mode);
    const sendModesDefaultdata = await this.profileService.GetDefaultDataForSendModes(this.EmployeeId);
    this.DefaultData = await sendModesDefaultdata;
    if(mode == 1){
      this.mailBlocks = true;
      this.address = this.DefaultData.Address;
    }else if(mode == 2){
      this.EmailBlocks = true;
      this.emails = this.DefaultData.PersonEmailID;
      this.SentDateBlock = false;
      this.mailBlocks = false;
    }
    else{
      this.mailBlocks = false;
      this.EmailBlocks = false;
      this.SentDateBlock = true;
    }   
  }

  downloadFile() {
    const downloadUrl = config.api_img_url + this.letterPath;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = downloadUrl;
    link.click();
  }

  sendEmployee(){
    this.errors = null;
    this.controllerFactory.validate()
    .then(result => {
       if (result.valid) {
    const sendEmployeeData = {
      EmployeeID : this.EmployeeId,
      EmployeeLetterID : this.letterId,
      SentMode : this.sentMode,
      SentDT : this.SentDate ? this.SentDate : null,
      ToEMail : this.emails ? this.emails : null,
      CcEmail : this.Ccemails ? this.Ccemails : null,
      Address : this.address ? this.address : null,
      Subject : this.subject ? this.subject : null,
      Body : this.body ? this.body : null,
      Comments : this.Comments ? this.Comments : null,
      LetterName : this.letterName,
      DocumentPath : this.letterPath,
      AckReceivedDT : "",
      AcknowledgementPath : "",
      SendAcknowledgementEmpIND : this.EmployeeAcknowledge,
      // EmployeeLetterID : "",
    }
    this.profileService.GetPostEmployeeLetter(sendEmployeeData)
    .then(() => {
      this.controller.ok(true);
      });
       }
      })
    
  }

  cancel() {
    this.controller.cancel();
  }
   
}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {validateTrigger, ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { DashboardService } from '../../../../shared/services/dashboardservice';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';

@inject(ProfileService,JwtService,DialogController,ValidationControllerFactory,DashboardService)
export class Uploadacknowledgement {
   
  ReceivedDate = '';
  uploadedFiles = [];

  constructor(ProfileService,JwtService,DialogController,controllerFactory,DashboardService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dashboardService = DashboardService
    this.controllerFactory = controllerFactory.createForCurrentScope();
      this.controllerFactory.validateTrigger = validateTrigger.change;
      this.Employeeid = this.jwtService.getEmployeeId();

    ValidationRules.customRule(
      'file',
      () =>{
      if(this.fileValidatorRule === true){
        return false;
      }else{
        return true;
      }
    },
    );
    ValidationRules
       .ensure('receivedDate').required().withMessage('Received date is required')
       .ensure('upload').required().withMessage('Acknowledgement is required')
       .on(this);
  }
  activate(data){
    console.log(data);
    this.EmployeeLettercommunicationhistoryID = data.EmployeeLettercommunicationhistoryID;
    this.sentDate = data.SentDT;
    this.GeneratedDate=data.GeneratedDT;
    console.log(this.GeneratedDate );
    this.ackName = data;
    this.SentModeText = data.SentModeText;
  }
  attached() {
    this.dashboardService.GetDashboardDetails(this.Employeeid).then(data => {
      const apiDatetimeStr = data.CurrentDate;
    const apiDatetime = new Date(apiDatetimeStr);
    const day = apiDatetime.getDate().toString().padStart(2, "0");
    const month = (apiDatetime.getMonth() + 1).toString().padStart(2, "0");
    const year = apiDatetime.getFullYear().toString();
    const serverDate = `${day}-${month}-${year}`;

    flatpickr(this.receivedDatePicker, {
      closeOnSelect: true,//new Date().toISOString().split("T")[0]
      dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
      minDate: this.GeneratedDate, // the minimum selectable date in the calendar (default is null, which means no limit)
      maxDate: serverDate, // the maximum selectable date in the calendar (default is null, which means no limit)
      defaultDate:' ',
      enableTime: false, // whether to allow selecting time in addition to date
      enableSeconds: false, // whether to allow selecting seconds in addition to time
      time_24hr: false, // whether to use a 24-hour clock for time input
      minuteIncrement: 1, // the increment for minutes in the time input
      hourIncrement: 1, // the increment for hours in the time input
      timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
      clickOpens:false,
      onClose: function (selectedDates, dateStr, instance) {
         this.ReceivedDate = dateStr;
         console.log(this.ReceivedDate);
      }
   });
   this.ReceivedcalendarIcon.addEventListener('click', () => {
      this.receivedDatePicker._flatpickr.open();
   });
    })
   this.fileValidator();
  }
  fileValidator(){
    const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
   }
   openFileDialog() {
    document.getElementById('attachments').click();
   }
   handleFileSelect(files) {
      const file = files[0];
      this.file = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      this.DocumentName = file.name;
      reader.onload = () => {
         this.file = reader.result;
      };
   }
   removeFile() {
      this.file = null;
      this.DocumentName = '';
      const fileInput = document.getElementById('attachments');
      if (fileInput) {
         fileInput.value = ''; // Clear the file input value
      }
   }
   saveAck(){
    this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              const AckData = {
                EmployeeLettercommunicationhistoryID : this.EmployeeLettercommunicationhistoryID,
                EmployeeID : this.Employeeid,
                AckReceivedDT : this.receivedDate,
                AcknowledgementPath : this.file,
                AcknowledgementName : this.DocumentName
              }
              this.profileService.PostUploadAck(AckData)
              .then(() =>{
                this.controller.ok(true);
              });
            }
          })
   }

  cancel() {
    this.controller.cancel();
 }
    validateField(data){
        if(data)
        {
            this.controllerFactory.clear();
        }
        ValidationRules
            .ensure('receivedDate').required().withMessage('Received date is required')
            .ensure('upload').required().withMessage('Acknowledgement is required')
            .on(this);
    }
}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import * as tinymce from 'tinymce';
import { Previewletter } from '../PreviewLetter/previewletter';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import Scrollbar from 'smooth-scrollbar';

@inject(ProfileService,JwtService,DialogController,DialogService,ValidationControllerFactory)
export class Addletter {

  showPreviewPopup = false;
  formOutput = {}; // Store the form output here
  selectedOption = '1';
  editorContent = '';
  EditorData = '';
    
  constructor(ProfileService,JwtService,DialogController,DialogService,controllerFactory) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dialogService = DialogService;
    this.controllerFactory = controllerFactory.createForCurrentScope();

    this.Employeeid = this.jwtService.getEmployeeId();

    ValidationRules
      .ensure('letterSubject').required().withMessage('Subject is required')
      .ensure('lettername').required().withMessage('Letter name is required')
      .ensure('selectedTemplate').required().withMessage('Please select a template')
      .ensure('selectedLetter').required().withMessage('Please select a letter')
      .ensure('editorContent').required().withMessage('The content field is required.')
      .on(this);
  }

  // activate() {
  //   setTimeout(()=>{       
  //   Scrollbar.init(document.querySelector('.addsletter-scrollbar'));
  // },1000)
  // }

  async attached() {
  tinymce.init({
    selector: '#content',
    toolbar: 'undo redo | code | image imagetools media filemanager | blocks fontfamily fontsize | bold italic underline strikethrough | preview | table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | codesample',
    plugins: 'code image imagetools media filemanager preview fullscreen codesample',

    setup: editor => {
      editor.on('init', () => {
          this.editor = editor;
          this.editorContent = '';
      });
      editor.on('change', () => {
          this.editorContent = editor.getContent();
      });
    },
    /* enable title field in the Image dialog*/
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,
    file_picker_types: 'image',
    /* and here's our custom image picker*/
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
  
      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
  
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const id = 'blobid' + (new Date()).getTime();
          const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);
          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        });
        reader.readAsDataURL(file);
      });
  
      input.click();
    },
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
  });
  
  const EmployeeLettersData = await this.profileService.GetEmployeeLetters(this.Employeeid);
  this.EmployeeLetters = await EmployeeLettersData;

  const EmployeeTemplatesData = await this.profileService.GetAllTemplates();
  this.EmployeeTemplates = await EmployeeTemplatesData;

  //GetTemplates
  const TemplatesData = await this.profileService.GetTemplates();
  this.Templates = await TemplatesData;

  // setTimeout(()=>{       
    // Scrollbar.init(document.querySelector('.addsletter-scrollbar'));
  // },1000)
  }

  selectedLetters(data){
    this.letterData = data;
    this.lettername = this.letterData.TemplateName;
    this.letterSubject = this.letterData.Subject;
    this.editor.setContent(this.letterData.Content);
    this.editor.focus();
  }

  selectedTemplates(data){
    this.templateData = data;
    this.editor.focus();
    if(this.templateData === ''){
      this.letterSubject = '';
      this.editor.setContent('');
      this.InputFocus.focus();
    }else{
    this.lettername = this.templateData.Name;
    this.letterSubject = this.templateData.Subject;
    this.editor.setContent(this.templateData.Content);
    }
  }

  handleRadioChange(event) {
    this.selectedOption = event.target.value;
    if(this.selectedOption === '1' || '3' || '2'){
      this.lettername = '';
      this.letterSubject = '';
      this.editor.setContent('');
    } 
  }

  previewLetter() {
    this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              const NewLetterData = {
                TemplateName : this.lettername,
                Subject : this.letterSubject,
                Content : this.editorContent,
                SendAcknowledgementEmpIND : this.Employeeid,
                DisciplinaryActionID : this.selectedOption,
              };
              this.profileService.GetPreviewLetterData(NewLetterData)
              .then(data =>{
                const previewData = {
                  Content: data
                };
                this.dialogService.open({viewModel:Previewletter,model: previewData});
              })
              //tinymce.activeEditor.execCommand('mcePreview');
              //this.dialogService.open({viewModel:Previewletter,model: NewLetterData});
            }
          })
  }

  GenerateLetter(){
    this.errors = null;
    this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              const NewLetterData = {
                TemplateName : this.lettername,
                Subject : this.letterSubject,
                Content : this.editorContent,
                SendAcknowledgementEmpIND : false,
                DisciplinaryActionID : null,
                EmployeeID :this.Employeeid
              };
              this.profileService.SaveEmployeeLetter(NewLetterData)
              .then(() => {
                this.controller.ok(true);
              });
            }
          });  
  }

  detached() {
    tinymce.remove(this.editor);
}

  cancel() {
    this.controller.cancel();
 }
    
}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { JwtService } from '../../../../shared/services/jwtservice';
import { ProfileService } from '../../../../shared/services/profielservice';
import { config } from '../../../../shared/services/config';

@inject(ProfileService,DialogController,JwtService)
export class Viewacknowledgement {
    
  URL= config.img_url;

  constructor(ProfileService,DialogController,JwtService) {
    this.profileService = ProfileService;
    this.controller = DialogController;
    this.jwtService = JwtService;

    this.Employeeid = this.jwtService.getEmployeeId();
  }

  activate(viewAckData) {
    this.sentDate = viewAckData.SentDT;
    this.File = viewAckData.AcknowledgementPath;
    this.SentModeText = viewAckData.SentModeText;
    this.EmployeeLettercommunicationhistoryID = viewAckData.EmployeeLettercommunicationhistoryID;
  }

  async attached() {
    const EditAckData = await this.profileService.EditUploadAck(this.EmployeeLettercommunicationhistoryID);
    this.EditAck = await EditAckData;
    this.DisplayFileName = this.EditAck.AcknowledgementName;
    this.receivedDate = this.EditAck.AckReceivedDT;
  }

  downloadFile() {
    const downloadUrl = this.URL+ this.File;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.download = downloadUrl;
    link.click();
  }

  cancel() {
    this.controller.cancel();
 }
    
}

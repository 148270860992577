import { ProfileService } from "../../../shared/services/profielservice";
import { JwtService } from "../../../shared/services/jwtservice";
import {inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,Router,LoginService)
export class Generalinformation {
  constructor(ProfileService,JwtService,router,loginService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.router = router;
      this.loginService = loginService;
  }
  attached(params) {

   // const empid = this.jwtService.getEmployeeId();
   var empid ='';
    const id = this.router.currentInstruction.queryParams.id;
    if(id != null && id !='')
    {
       empid=id;
    }
    else{
     empid=this.jwtService.getEmployeeId();
    }
        this.profileService.EmployeeDetails(empid).then(data => {
            this.generalInformation=data;
        });
  }
}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import * as tinymce from 'tinymce';
import { Previewletter } from '../PreviewLetter/previewletter';

@inject(ProfileService,JwtService,DialogController,DialogService)
export class Generateletter {
  previewContentData = '';
   
  constructor(ProfileService,JwtService,DialogController,DialogService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.dialogService = DialogService;

    this.Employeeid = this.jwtService.getEmployeeId();
  }

  activate(Data) {
    this.EditData = Data;
    this.lettername = this.EditData.TemplateName;
    this.letterSubject = this.EditData.Subject;
    this.editorContent = this.EditData.Content;
    this.letterId = this.EditData.EmployeeLetterID;
  }

  attached() {
    tinymce.init({
      selector: '#content',
      toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
      plugins: 'code image imagetools media',
      setup: editor => {
          editor.on('init', () => {
              this.editor = editor;
              this.editorContent = '';
          });
          editor.on('change', () => {
              this.editorContent = editor.getContent();
          });
      },
  });
  this.previewContentData = this.editorContent;
}

previewLetter(){
  const ToPreviewCurrentContent = this.previewContentData ? this.previewContentData : (this.editorContent ? this.editorContent + this.previewContentData : null);
  const previewData = {
    Content : ToPreviewCurrentContent,
  };
  this.dialogService.open({viewModel:Previewletter,model:previewData})
}

GenerateLetter(){
  const NewLetterData = {
    TemplateName : this.lettername,
    Subject : this.letterSubject,
    Content : this.editorContent,
    SendAcknowledgementEmpIND : false,
    DisciplinaryActionID : null,
    EmployeeID :this.Employeeid,
    EmployeeLetterID : this.letterId
  };
  this.profileService.SaveEmployeeLetter(NewLetterData)
  .then(response => {
    this.controller.ok(true);
  })
}

cancel() {
  this.controller.cancel();
}
    
}

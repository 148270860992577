import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';
import { config } from '../../../../shared/services/config';
import { DashboardService } from '../../../../shared/services/dashboardservice';

@inject(ProfileService,JwtService,DialogController,ValidationControllerFactory,DashboardService)
export class Editacknowledgement {

  viewFile = true;
  ackreq = false;
  URL=config.img_url;
    
  constructor(ProfileService,JwtService,DialogController,controllerFactory,DashboardService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.controller = DialogController;
    this.controllerFactory = controllerFactory.createForCurrentScope();
    this.dashboardService = DashboardService;

    this.Employeeid = this.jwtService.getEmployeeId();

    ValidationRules
       .ensure('receivedDate').required().withMessage('Received date is required')
       .ensure('upload').required().withMessage('Acknowledgement is required')
       .on(this);
  }

  activate(editAckData) {
    console.log(editAckData);
    this.EmployeeLettercommunicationhistoryID = editAckData.EmployeeLettercommunicationhistoryID;
    this.SentModeText = editAckData.SentModeText;
    this.sentDate = editAckData.SentDT;
    this.GeneratedDT=editAckData.GeneratedDT;
  }

  async attached() {
    this.dashboardService.GetDashboardDetails(this.Employeeid).then(data => {
      const apiDatetimeStr = data.CurrentDate;
      const apiDatetime = new Date(apiDatetimeStr);
      const day = apiDatetime.getDate().toString().padStart(2, "0");
      const month = (apiDatetime.getMonth() + 1).toString().padStart(2, "0");
      const year = apiDatetime.getFullYear().toString();
      const serverDate = `${day}-${month}-${year}`;

    flatpickr(this.receivedDatePicker, {
      closeOnSelect: true,//new Date().toISOString().split("T")[0]
      dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
      minDate: this.GeneratedDT, // the minimum selectable date in the calendar (default is null, which means no limit)
      maxDate: serverDate, // the maximum selectable date in the calendar (default is null, which means no limit)
      defaultDate: ' ', // the initial date that should be shown in the calendar (default is null, which means today's date)
      enableTime: false, // whether to allow selecting time in addition to date
      enableSeconds: false, // whether to allow selecting seconds in addition to time
      time_24hr: false, // whether to use a 24-hour clock for time input
      minuteIncrement: 1, // the increment for minutes in the time input
      hourIncrement: 1, // the increment for hours in the time input
      timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
      clickOpens:false,
      onClose: function (selectedDates, dateStr, instance) {
         this.ReceivedDate = dateStr;
      }
   });
   console.log(this.sentDate);
   this.ReceivedcalendarIcon.addEventListener('click', () => {
      this.receivedDatePicker._flatpickr.open();
   });
  })
   const EditAckData = await this.profileService.EditUploadAck(this.EmployeeLettercommunicationhistoryID);
   this.EditAck = await EditAckData;
   console.log(this.EditAck);
   this.sentDate = this.EditAck.SentDT;
   this.receivedDate = this.EditAck.AckReceivedDT;
   this.documentFileName = this.EditAck.AcknowledgementName;
   this.file = this.EditAck.AcknowledgementPath;
   this.upload = "required";
   this.fileValidator();
  }

  fileValidator(){
    const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
                this.fileValidatorRule = true;
            } else {
                this.fileMessage=null;
                this.fileValidatorRule = false;
            }
        });
   }

   handleFileSelect(files) {
    this.uploadErrors = [];
    this.viewFile = false;
    const file = files[0];
    this.file = file;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    this.documentFileName = file.name;
    reader.onload = () => {
        this.file = reader.result;
        this.ackreq = false;
    };
}
openFileDialog() {
    document.getElementById('attachments').click();
}

removeFile() {

    this.file = null;
    this.documentFileName = '';
    const fileInput = document.getElementById('attachments');
    if (fileInput) {
        fileInput.value = ''; // Clear the file input value
    }
}

downloadFile() {
  const downloadUrl = this.URL + this.file;
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.target = '_blank';
  link.download = downloadUrl;
  link.click();
}

   saveAck(){
    if(this.documentFileName == ''){
      this.ackreq = true;
    }else{
    this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
              this.ackreq = false;
              const EditAckData = {
                EmployeeLettercommunicationhistoryID : this.EmployeeLettercommunicationhistoryID,
                EmployeeID : this.Employeeid,
                AckReceivedDT : this.receivedDate,
                AcknowledgementPath : this.file,
                AcknowledgementName : this.documentFileName
              }
              this.profileService.PostUploadAck(EditAckData)
              .then(() =>{
                this.controller.ok(true);
              });
            }
          }) 
        }
   }

  cancel() {
    this.controller.cancel();
 }
    
}
